
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonus from "@/mixins/mixinBonus";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";
  import BonusTableBranchselector from "@/components/bonus/table/Branchselector.vue";
  import BonusPagerHead from "@/components/bonus/pager/Head.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      BonusTableBranchselector,
      BonusPagerHead,
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinBonus) {
    gensen_pay() {
      this.m.gensen_pay({
        yearmonth: util.formatDate(util.date2obj(this.bonus_info.paid_on), "yyyy-mm"),
      });
    }
    bonus_csv() {
      this.m.bonus_csv({
        branch_id: this.viewing_branch,
        yearmonth: util.formatDate(util.date2obj(this.bonus_info.paid_on), "yyyy-mm"),
        bonus_info_id: this.bonus_info_id,
      });
    }

    async zengin() {
      await this.m.zengin({
        branch_id: this.viewing_branch,
        bonus_info_id: this.bonus_info_id,
        yearmonth: util.formatDate(util.date2obj(this.bonus_info.paid_on), "yyyy-mm"),
      });
    }
    async zengin_zip() {
      await this.m.zengin_zip({
        branch_id: this.viewing_branch,
        bonus_info_id: this.bonus_info_id,
        yearmonth: util.formatDate(util.date2obj(this.bonus_info.paid_on), "yyyy-mm"),
      });
    }

    update_each_bonus_payments() {
      this.m.updateEachBonusPayments({
        branch_id: this.viewing_branch,
        bonus_info_id: this.bonus_info_id,
        employee_hash: this.employee_hash,
        saved_bonus_allowances: this.saved_bonus_allowances,
        saved_bonus_deductions: this.saved_bonus_deductions,
        saved_bonus_other_allowances: this.saved_bonus_other_allowances,
      });
    }

    lock() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash_all[this.viewing_branch];
      this.m.lock({
        bonus_info_id: this.bonus_info_id,
        yearmonth: util.date2yearmonth(`${this.bonus_info.year}-${this.bonus_info.month}-01`),
        branch_id: this.viewing_branch,
        branch_name,
        is_nencho: this.nencho !== null,
      });
    }
    unlock() {
      const branch_name =
        this.viewing_branch == 0 ? "全店舗" : this.branch_hash_all[this.viewing_branch];
      this.m.unlock({
        bonus_info_id: this.bonus_info_id,
        branch_id: this.viewing_branch,
        branch_name,
      });
    }

    edit_each_bonus_payments() {
      this.query = {
        is_open_bonus_allowance: true,
        is_open_bonus_deduction: true,
        is_open_bonus_other: true,
      };

      this.each_bonus_payments.forEach((each_bonus_payment) => {
        if (
          this.lock_types_hash[this.employee_hash[each_bonus_payment.employee_id].branch_id] == 0
        ) {
          this.m.setEachBonus({
            employee_id: each_bonus_payment.employee_id,
            each_bonus_payment_object: {
              each_bonus_allowances: util.deep_copy(each_bonus_payment.each_bonus_allowances),
              each_bonus_deductions: util.deep_copy(each_bonus_payment.each_bonus_deductions),
              each_bonus_other_allowances: util.deep_copy(
                each_bonus_payment.each_bonus_other_allowances,
              ),

              money: each_bonus_payment.money,

              health_ins: each_bonus_payment.health_ins,
              care_ins: each_bonus_payment.care_ins,
              pension_ins: each_bonus_payment.pension_ins,
              emp_ins: each_bonus_payment.emp_ins,
              social_ins_adj: each_bonus_payment.social_ins_adj,
              income_tax: each_bonus_payment.income_tax,
              fixed_tax_reduction: each_bonus_payment.is_valid_fixed_tax_reduction
                ? each_bonus_payment.fixed_tax_reduction
                : null,

              yearend_adj: each_bonus_payment.yearend_adj,
              settlement: each_bonus_payment.settlement,

              paid_cash: each_bonus_payment.paid_cash,

              custom_paid_on: each_bonus_payment.custom_paid_on
                ? each_bonus_payment.custom_paid_on
                : this.bonus_info.paid_on,
            },
          });
        }
      });

      this.m.editEachBonusPayments();
    }

    get is_able_lock() {
      if (this.viewing_branch == 0) {
        return this.bonus_info.lock_type == 0;
      } else {
        return this.lock_types_hash[this.viewing_branch] == 0;
      }
    }
    get is_able_unlock() {
      if (this.viewing_branch == 0) {
        return this.bonus_info.lock_type == 2;
      } else {
        return this.lock_types_hash[this.viewing_branch] == 2;
      }
    }

    recalc() {
      this.m.recalc({ bonus_info_id: this.bonus_info_id });
    }

    add_bonus_info() {
      const dateobj = new Date();
      const bonus_info = {
        close_started_on: util.formatDate(dateobj, "yyyy-mm-dd"),
        close_ended_on: util.formatDate(dateobj, "yyyy-mm-dd"),
        paid_on: util.formatDate(dateobj, "yyyy-mm-dd"),
      };
      this.m.setBonusInfo({ bonus_info });
      this.m.setIsOpenAddBonusInfoDialog(true);
    }
    edit_bonus_info() {
      const dateobj = new Date();
      const bonus_info = {
        close_started_on: this.bonus_info.close_started_on,
        close_ended_on: this.bonus_info.close_ended_on,
        paid_on: this.bonus_info.paid_on,
      };
      this.m.setBonusInfo({ bonus_info });
      this.m.setIsOpenEditBonusInfoDialog(true);
    }
    delete_bonus_info() {
      this.m.deleteBonusInfo({ bonus_info_id: this.bonus_info_id });
    }

    get is_able_calc() {
      if (this.viewing_branch == 0) {
        if (this.bonus_info.lock_type == 2) {
          return 1; //給与ロックがされてしまっている
        } else if (this.global.is_each_bonus_lock) {
          return 2; //1店舗でも給与ロックがされてしまっている。
        } else {
          return 0;
        }
      } else {
        if (this.lock_types_hash[this.viewing_branch] == 2) {
          return 1; //給与ロックがされてしまっている
        } else if (this.global.is_each_bonus_lock) {
          return 2; //1店舗でも給与ロックがされてしまっている。
        } else {
          return 0;
        }
      }
    }
  }
