
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonus from "@/mixins/mixinBonus";
  import global from "@/vuex/bonus/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";
  import BonusTableInputrow from "@/components/bonus/table/Inputrow.vue";
  import BonusTableRow from "@/components/bonus/table/Row.vue";

  @Component({
    components: {
      BonusTableInputrow,
      BonusTableRow,
    },
  })
  export default class Table extends Mixins(mixinBonus) {
    get sum() {
      const sum = {
        money: 0,
        allowance_pay: {},
        plus_sum: 0,

        health_ins: 0,
        care_ins: 0,
        pension_ins: 0,
        emp_ins: 0,
        social_ins_adj: 0,
        income_tax: 0,
        fixed_tax_reduction: 0,
        res_tax: 0,
        deduction_pay: {},
        minus_sum: 0,

        yearend_adj: 0,
        settlement: 0,
        other_allowance_pay: {},
        other_sum: 0,

        real_pay: 0,
        paid_cash: 0,
      };

      this.saved_bonus_allowances.forEach((allowance) => {
        sum.allowance_pay[allowance.id] = 0;
      });
      this.saved_bonus_deductions.forEach((deduction) => {
        sum.deduction_pay[deduction.id] = 0;
      });
      this.saved_bonus_other_allowances.forEach((other_allowance) => {
        sum.other_allowance_pay[other_allowance.id] = 0;
      });

      this.each_bonus_payments.forEach((each_bonus_payment) => {
        (sum.money += each_bonus_payment.money),
          (sum.plus_sum += each_bonus_payment.plus_sum),
          (sum.health_ins += each_bonus_payment.health_ins),
          (sum.care_ins += each_bonus_payment.care_ins),
          (sum.pension_ins += each_bonus_payment.pension_ins),
          (sum.emp_ins += each_bonus_payment.emp_ins),
          (sum.social_ins_adj += each_bonus_payment.social_ins_adj),
          (sum.income_tax += each_bonus_payment.income_tax),
          (sum.fixed_tax_reduction += each_bonus_payment.fixed_tax_reduction),
          (sum.minus_sum += each_bonus_payment.minus_sum),
          (sum.yearend_adj += each_bonus_payment.yearend_adj),
          (sum.settlement += each_bonus_payment.settlement),
          (sum.other_sum += each_bonus_payment.other_sum),
          (sum.real_pay += each_bonus_payment.real_pay),
          (sum.paid_cash += each_bonus_payment.paid_cash),
          this.saved_bonus_allowances.forEach((allowance) => {
            sum.allowance_pay[allowance.id] += each_bonus_payment.each_bonus_allowances[
              allowance.id
            ]
              ? each_bonus_payment.each_bonus_allowances[allowance.id].money
              : 0;
          });
        this.saved_bonus_deductions.forEach((deduction) => {
          sum.deduction_pay[deduction.id] += each_bonus_payment.each_bonus_deductions[deduction.id]
            ? each_bonus_payment.each_bonus_deductions[deduction.id].money
            : 0;
        });
        this.saved_bonus_other_allowances.forEach((other_allowance) => {
          sum.other_allowance_pay[other_allowance.id] += each_bonus_payment
            .each_bonus_other_allowances[other_allowance.id]
            ? each_bonus_payment.each_bonus_other_allowances[other_allowance.id].money
            : 0;
        });
      });

      return sum;
    }

    get allowance_colspan() {
      if (!this.is_open_allowance && !this.m.edit_each_bonus_payments_flag) {
        return 1;
      }
      let sum = 2;
      sum += this.saved_bonus_allowances.length;
      return sum;
    }
    get deduction_colspan() {
      if (!this.is_open_deduction && !this.m.edit_each_bonus_payments_flag) {
        return 1;
      }
      let sum = 7;
      if (this.is_fixed_tax_reduction) sum++;
      sum += this.saved_bonus_deductions.length;
      return sum;
    }
    get other_colspan() {
      if (!this.is_open_other && !this.m.edit_each_bonus_payments_flag) {
        return 1;
      }
      let sum = 3;
      sum += this.saved_bonus_other_allowances.length;
      return sum;
    }
    get table_column() {
      return this.allowance_colspan + this.deduction_colspan + this.other_colspan;
    }
  }
