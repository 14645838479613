
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonus from '@/mixins/mixinBonus';
import global from "@/vuex/bonus/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/bonus/manager";
import BonusTableControl from "@/components/bonus/table/Control.vue";
import BonusTableTable from "@/components/bonus/table/Table.vue";
import BonusPagerFoot from "@/components/bonus/pager/Foot.vue";
import BonusDialogExcelMain from "@/components/bonus/dialog/excel/Main.vue";

@Component({
  components: {
    BonusTableControl,
    BonusTableTable,
    BonusPagerFoot,
    BonusDialogExcelMain,
  }
})
export default class Main extends Mixins(mixinBonus) {
}
