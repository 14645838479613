
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonus from '@/mixins/mixinBonus';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/bonus/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Control extends Mixins(mixinBonus) {

}
