
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import dialog from "@/vuex/dialog";
  import mixinBonus from "@/mixins/mixinBonus";
  import BonusNobonus from "@/components/bonus/Nobonus.vue";
  import BonusMonthchanger from "@/components/bonus/Monthchanger.vue";
  import BonusMain from "@/components/bonus/Main.vue";
  import BonusDialogAddbonus from "@/components/bonus/dialog/Addbonus.vue";
  import BonusDialogEditbonus from "@/components/bonus/dialog/Editbonus.vue";

  @Component({
    components: {
      BonusNobonus,
      BonusMonthchanger,
      BonusMain,
      BonusDialogAddbonus,
      BonusDialogEditbonus,
    },
  })
  export default class Bonus extends Mixins(mixinBonus) {}
