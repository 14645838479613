import { render, staticRenderFns } from "./Controlbutton.vue?vue&type=template&id=642271b3&scoped=true&lang=pug"
import script from "./Controlbutton.vue?vue&type=script&lang=ts"
export * from "./Controlbutton.vue?vue&type=script&lang=ts"
import style0 from "./Controlbutton.vue?vue&type=style&index=0&id=642271b3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642271b3",
  null
  
)

export default component.exports