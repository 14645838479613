
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonus from '@/mixins/mixinBonus';
import dialog from "@/vuex/dialog";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Errorcheck extends Mixins(mixinBonus) {
  bonus_excel() {
    this.m.bonus_excel({paid_on: this.bonus_info.paid_on, bonus_info_id: this.bonus_info.id});
  }
}
