
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonus from "@/mixins/mixinBonus";
  import global from "@/vuex/bonus/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";

  @Component({
    components: {},
  })
  export default class Row extends Mixins(mixinBonus) {
    @Prop() each_bonus_payment;
  }
