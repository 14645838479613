
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonus from '@/mixins/mixinBonus';
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinBonus) {
  get monthstr() {
    if (this.global)
      return util.formatDate(util.date2obj(this.bonus_info.paid_on), 'yyyy年mm月支給分');
    else
      return '';
  }

  prev_bonus() {
    this.param_bonus_info_id = this.prev_bonus_info_id;
  }
  next_bonus() {
    this.param_bonus_info_id = this.next_bonus_info_id;
  }

  get lock_type() {
    return this.viewing_branch == 0 ? this.bonus_info.lock_type : this.lock_types_hash[this.viewing_branch];
  }
}
