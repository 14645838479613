import { Component, Vue, Mixins } from "vue-property-decorator";
import monthly from "@/vuex/bonus/monthly";
import each_monthly from "@/vuex/bonus_each/monthly";
import global from "@/vuex/bonus/global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/bonus/manager";
import { BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinBonus extends Mixins(utilMixins) {
  changed_disp_num: boolean = false;

  get is_set_global(): boolean {
    return global.data[this.bonus_info_id] && global.data[this.bonus_info_id].is_set;
  }
  get is_set_monthly(): boolean {
    return (
      monthly.data[this.bonus_info_id] &&
      monthly.data[this.bonus_info_id][this.viewing_branch] &&
      monthly.data[this.bonus_info_id][this.viewing_branch].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.data[this.bonus_info_id] && global.data[this.bonus_info_id].is_valid;
  }
  get is_valid_monthly(): boolean {
    return (
      monthly.data[this.bonus_info_id] &&
      monthly.data[this.bonus_info_id][this.viewing_branch] &&
      monthly.data[this.bonus_info_id][this.viewing_branch].is_valid
    );
  }

  async get_global() {
    if (this.param_bonus_info_id == 0) await global.fetch({ bonus_info_id: 0, auth: this.s_auth });
    else await global.fetch({ bonus_info_id: this.bonus_info_id, auth: this.s_auth });
  }
  async get_monthly() {
    await monthly.fetch({ branch_id: this.viewing_branch, bonus_info_id: this.bonus_info_id });
  }

  get global() {
    if ((!this.is_set_global || !this.is_valid_global) && this.bonus_info_id !== 0)
      this.get_global();
    if (this.is_set_global) return global.data[this.bonus_info_id];
  }
  get monthly() {
    if ((!this.is_set_global || !this.is_valid_global) && this.bonus_info_id !== 0)
      this.get_global(); //get_monthlyでglobalを使用するため
    else if ((!this.is_set_monthly || !this.is_valid_monthly) && this.bonus_info_id !== 0)
      this.get_monthly();
    if (this.is_set_monthly) return monthly.data[this.bonus_info_id][this.viewing_branch];
  }

  get bonus_info_id() {
    if (this.param_bonus_info_id == 0) {
      this.param_bonus_info_id = global.latest_bonus_info_id;
      return global.latest_bonus_info_id;
    } else {
      return this.param_bonus_info_id;
    }
  }
  set bonus_info_id(val) {
    this.param_bonus_info_id = val;
  }

  get prev_bonus_info_id() {
    if (this.global) return this.global.prev_bonus_info_id;
  }
  get next_bonus_info_id() {
    if (this.global) return this.global.next_bonus_info_id;
  }

  get bonus_info() {
    return this.global.bonus_info;
  }
  get nencho() {
    //12月賞与は年調後にロックできない仕組みを作成
    return this.global.nencho;
  }

  get saved_bonus_allowances() {
    return this.global.saved_bonus_allowances;
  }
  get saved_bonus_deductions() {
    return this.global.saved_bonus_deductions;
  }
  get saved_bonus_other_allowances() {
    return this.global.saved_bonus_other_allowances;
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage("branch_id"));
    }
    if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch) {
      branch_id = 0;
    }
    this.query = { viewing_branch: branch_id };
    return branch_id;
  }
  set viewing_branch(val) {
    this.page = 1;
    each_monthly.disable_all();
    this.query = { viewing_branch: val };
  }

  get is_fixed_tax_reduction() {
    return this.monthly.is_fixed_tax_reduction;
  }

  get m() {
    return manager;
  }

  get todayobj() {
    return new Date();
  }

  get branch_hash_object() {
    return this.global.branch_hash_object;
  }

  get param_bonus_info_id() {
    var bonus_info_id = this.query.bonus_info_id;
    if (bonus_info_id === undefined) {
      bonus_info_id = 0;
      this.query = { bonus_info_id };
    }
    return bonus_info_id;
  }
  set param_bonus_info_id(val) {
    if (val != this.param_bonus_info_id) {
      this.query_push = { bonus_info_id: val };
    }
  }

  get search_key() {
    var search_key = this.query.bonus_search_key;
    if (search_key === undefined || search_key == "") {
      this.query = { bonus_search_key: undefined };
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == "" || val == null) {
      this.query = { bonus_search_key: undefined };
    } else {
      this.query = { bonus_search_key: val };
    }
  }

  get is_open_allowance() {
    return this.query.is_open_bonus_allowance;
  }
  set is_open_allowance(val) {
    if (val == "" || val == null) {
      this.query = { is_open_bonus_allowance: undefined };
    } else {
      this.query = { is_open_bonus_allowance: val };
    }
  }
  get is_open_deduction() {
    return this.query.is_open_bonus_deduction;
  }
  set is_open_deduction(val) {
    if (val == "" || val == null) {
      this.query = { is_open_bonus_deduction: undefined };
    } else {
      this.query = { is_open_bonus_deduction: val };
    }
  }
  get is_open_other() {
    return this.query.is_open_bonus_other;
  }
  set is_open_other(val) {
    if (val == "" || val == null) {
      this.query = { is_open_bonus_other: undefined };
    } else {
      this.query = { is_open_bonus_other: val };
    }
  }

  get sort_code() {
    return this.query.bonus_sort_code;
  }
  set sort_code(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_code: undefined };
    } else {
      this.query = { bonus_sort_code: val };
    }
  }
  get sort_name() {
    return this.query.bonus_sort_name;
  }
  set sort_name(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_name: undefined };
    } else {
      this.query = { bonus_sort_name: val };
    }
  }
  get sort_hiring_type() {
    return this.query.bonus_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_hiring_type: undefined };
    } else {
      this.query = { bonus_sort_hiring_type: val };
    }
  }
  get sort_branch_id() {
    return this.query.bonus_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_branch_id: undefined };
    } else {
      this.query = { bonus_sort_branch_id: val };
    }
  }

  get sort_allowance() {
    return this.query.bonus_sort_allowance;
  }
  set sort_allowance(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_allowance: undefined };
    } else {
      this.query = { bonus_sort_allowance: val };
    }
  }
  get sort_deduction() {
    return this.query.bonus_sort_deduction;
  }
  set sort_deduction(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_deduction: undefined };
    } else {
      this.query = { bonus_sort_deduction: val };
    }
  }
  get sort_other_allowance() {
    return this.query.bonus_sort_other_allowance;
  }
  set sort_other_allowance(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_other_allowance: undefined };
    } else {
      this.query = { bonus_sort_other_allowance: val };
    }
  }
  get sort_real_pay() {
    return this.query.bonus_sort_real_pay;
  }
  set sort_real_pay(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_real_pay: undefined };
    } else {
      this.query = { bonus_sort_real_pay: val };
    }
  }
  get sort_paid_cash() {
    return this.query.bonus_sort_paid_cash;
  }
  set sort_paid_cash(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_paid_cash: undefined };
    } else {
      this.query = { bonus_sort_paid_cash: val };
    }
  }
  get sort_sum_tax() {
    return this.query.bonus_sort_sum_tax;
  }
  set sort_sum_tax(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_sum_tax: undefined };
    } else {
      this.query = { bonus_sort_sum_tax: val };
    }
  }
  get sort_paid_on() {
    return this.query.bonus_sort_paid_on;
  }
  set sort_paid_on(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_paid_on: undefined };
    } else {
      this.query = { bonus_sort_paid_on: val };
    }
  }

  //給与の二行目
  get sort_money() {
    return this.query.bonus_sort_money;
  }
  set sort_money(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_money: undefined };
    } else {
      this.query = { bonus_sort_money: val };
    }
  }
  get sort_plus_sum() {
    return this.query.bonus_sort_plus_sum;
  }
  set sort_plus_sum(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_plus_sum: undefined };
    } else {
      this.query = { bonus_sort_plus_sum: val };
    }
  }

  get sort_health_ins() {
    return this.query.bonus_sort_health_ins;
  }
  set sort_health_ins(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_health_ins: undefined };
    } else {
      this.query = { bonus_sort_health_ins: val };
    }
  }
  get sort_care_ins() {
    return this.query.bonus_sort_care_ins;
  }
  set sort_care_ins(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_care_ins: undefined };
    } else {
      this.query = { bonus_sort_care_ins: val };
    }
  }
  get sort_pension_ins() {
    return this.query.bonus_sort_pension_ins;
  }
  set sort_pension_ins(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_pension_ins: undefined };
    } else {
      this.query = { bonus_sort_pension_ins: val };
    }
  }
  get sort_emp_ins() {
    return this.query.bonus_sort_emp_ins;
  }
  set sort_emp_ins(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_emp_ins: undefined };
    } else {
      this.query = { bonus_sort_emp_ins: val };
    }
  }
  get sort_social_ins_adj() {
    return this.query.bonus_sort_social_ins_adj;
  }
  set sort_social_ins_adj(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_social_ins_adj: undefined };
    } else {
      this.query = { bonus_sort_social_ins_adj: val };
    }
  }
  get sort_income_tax() {
    return this.query.bonus_sort_income_tax;
  }
  set sort_income_tax(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_income_tax: undefined };
    } else {
      this.query = { bonus_sort_income_tax: val };
    }
  }
  get sort_minus_sum() {
    return this.query.bonus_sort_minus_sum;
  }
  set sort_minus_sum(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_minus_sum: undefined };
    } else {
      this.query = { bonus_sort_minus_sum: val };
    }
  }

  get sort_yearend_adj() {
    return this.query.bonus_sort_yearend_adj;
  }
  set sort_yearend_adj(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_yearend_adj: undefined };
    } else {
      this.query = { bonus_sort_yearend_adj: val };
    }
  }
  get sort_settlement() {
    return this.query.bonus_sort_settlement;
  }
  set sort_settlement(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_settlement: undefined };
    } else {
      this.query = { bonus_sort_settlement: val };
    }
  }
  get sort_other_sum() {
    return this.query.bonus_sort_other_sum;
  }
  set sort_other_sum(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_other_sum: undefined };
    } else {
      this.query = { bonus_sort_other_sum: val };
    }
  }

  get sort_lock_type() {
    return this.query.bonus_sort_lock_type;
  }
  set sort_lock_type(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_lock_type: undefined };
    } else {
      this.query = { bonus_sort_lock_type: val };
    }
  }

  get sort_allowance_id() {
    return this.query.bonus_sort_allowance_id;
  }
  set sort_allowance_id(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_allowance_id: undefined };
    } else {
      this.query = { bonus_sort_allowance_id: val };
    }
  }
  get sort_deduction_id() {
    return this.query.bonus_sort_deduction_id;
  }
  set sort_deduction_id(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_deduction_id: undefined };
    } else {
      this.query = { bonus_sort_deduction_id: val };
    }
  }
  get sort_other_allowance_id() {
    return this.query.bonus_sort_other_allowance_id;
  }
  set sort_other_allowance_id(val) {
    if (val == "" || val == null) {
      this.query = { bonus_sort_other_allowance_id: undefined };
    } else {
      this.query = { bonus_sort_other_allowance_id: val };
    }
  }

  get page() {
    var page = this.query.bonus_page;
    if (page === undefined || page == "") {
      this.query = { bonus_page: 1 };
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == "" || val == null) {
      this.query = { bonus_page: undefined };
    } else {
      this.query = { bonus_page: val };
    }
  }

  get searched_sorted_each_bonus_payments() {
    var each_bonus_payments = util.deep_copy(this.each_bonus_payments);
    each_bonus_payments = this.search_each_bonus_payments(each_bonus_payments);
    each_bonus_payments = this.sort_each_bonus_payments(each_bonus_payments);
    return each_bonus_payments;
  }
  get managed_each_bonus_payments() {
    var each_bonus_payments = util.deep_copy(this.searched_sorted_each_bonus_payments);
    each_bonus_payments = this.page_each_bonus_payments(each_bonus_payments);
    return each_bonus_payments;
  }
  search_each_bonus_payments(each_bonus_payments) {
    if (this.search_key === null) {
      return each_bonus_payments;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter((str) => str != "");
      var _this = this;
      return each_bonus_payments.filter((each_bonus_payment) => {
        var flag = 0;
        search_keys.forEach((key) => {
          if (
            this.employee_hash[each_bonus_payment.employee_id].name
              .toLowerCase()
              .indexOf(key.toLowerCase()) != -1 ||
            util.search_kana(this.employee_hash[each_bonus_payment.employee_id].kana, key) ||
            (this.employee_hash[each_bonus_payment.employee_id].code &&
              this.employee_hash[each_bonus_payment.employee_id].code
                .toLowerCase()
                .indexOf(key.toLowerCase()) != -1)
          ) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_each_bonus_payments(each_bonus_payments) {
    if (this.sort_code)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          this.sort_code,
        ),
      );
    else if (this.sort_name)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          this.sort_name,
        ),
      );
    else if (this.sort_hiring_type)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].hiring_type,
          this.employee_hash[y.employee_id].hiring_type,
          this.sort_hiring_type,
        ),
      );
    else if (this.sort_branch_id)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].branch_id,
          this.employee_hash[y.employee_id].branch_id,
          this.sort_branch_id,
        ),
      );
    else if (this.sort_money)
      each_bonus_payments.sort((x, y) => util.sort_idx(x.money, y.money, this.sort_money));
    else if (this.sort_plus_sum)
      each_bonus_payments.sort((x, y) => util.sort_idx(x.plus_sum, y.plus_sum, this.sort_plus_sum));
    else if (this.sort_health_ins)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.health_ins, y.health_ins, this.sort_health_ins),
      );
    else if (this.sort_care_ins)
      each_bonus_payments.sort((x, y) => util.sort_idx(x.care_ins, y.care_ins, this.sort_care_ins));
    else if (this.sort_pension_ins)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.pension_ins, y.pension_ins, this.sort_pension_ins),
      );
    else if (this.sort_emp_ins)
      each_bonus_payments.sort((x, y) => util.sort_idx(x.emp_ins, y.emp_ins, this.sort_emp_ins));
    else if (this.sort_social_ins_adj)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.social_ins_adj, y.social_ins_adj, this.sort_social_ins_adj),
      );
    else if (this.sort_income_tax)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.income_tax, y.income_tax, this.sort_income_tax),
      );
    else if (this.sort_minus_sum)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.minus_sum, y.minus_sum, this.sort_minus_sum),
      );
    else if (this.sort_yearend_adj)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.yearend_adj, y.yearend_adj, this.sort_yearend_adj),
      );
    else if (this.sort_settlement)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.settlement, y.settlement, this.sort_settlement),
      );
    else if (this.sort_other_sum)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.other_sum, y.other_sum, this.sort_other_sum),
      );
    else if (this.sort_real_pay)
      each_bonus_payments.sort((x, y) => util.sort_idx(x.real_pay, y.real_pay, this.sort_real_pay));
    else if (this.sort_paid_cash)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.paid_cash, y.paid_cash, this.sort_paid_cash),
      );
    else if (this.sort_sum_tax)
      each_bonus_payments.sort((x, y) => util.sort_idx(x.sum_tax, y.sum_tax, this.sort_sum_tax));
    else if (this.sort_paid_on)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(x.custom_paid_on, y.custom_paid_on, this.sort_paid_on),
      );
    else if (this.sort_allowance)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          x.each_bonus_allowances[this.sort_allowance_id]
            ? x.each_bonus_allowances[this.sort_allowance_id].money
            : 0,
          y.each_bonus_allowances[this.sort_allowance_id]
            ? y.each_bonus_allowances[this.sort_allowance_id].money
            : 0,
          this.sort_allowance,
        ),
      );
    else if (this.sort_deduction)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          x.each_bonus_deductions[this.sort_deduction_id]
            ? x.each_bonus_deductions[this.sort_deduction_id].money
            : 0,
          y.each_bonus_deductions[this.sort_deduction_id]
            ? y.each_bonus_deductions[this.sort_deduction_id].money
            : 0,
          this.sort_deduction,
        ),
      );
    else if (this.sort_other_allowance)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          x.each_bonus_other_allowances[this.sort_other_allowance_id]
            ? x.each_bonus_other_allowances[this.sort_other_allowance_id].money
            : 0,
          y.each_bonus_other_allowances[this.sort_other_allowance_id]
            ? y.each_bonus_other_allowances[this.sort_other_allowance_id].money
            : 0,
          this.sort_other_allowance,
        ),
      );
    else if (this.sort_lock_type)
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.lock_types_hash[this.employee_hash[x.employee_id].branch_id],
          this.lock_types_hash[this.employee_hash[y.employee_id].branch_id],
          this.sort_lock_type,
        ),
      );
    else {
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].kana,
          this.employee_hash[y.employee_id].kana,
          "asc",
        ),
      );
      each_bonus_payments.sort((x, y) =>
        util.sort_idx(
          this.employee_hash[x.employee_id].code,
          this.employee_hash[y.employee_id].code,
          "asc",
        ),
      );
      if (this.viewing_branch == 0) {
        each_bonus_payments.sort((x, y) =>
          util.sort_idx(
            this.employee_hash[x.employee_id].branch_id,
            this.employee_hash[y.employee_id].branch_id,
            "asc",
          ),
        );
      }
    }

    return each_bonus_payments;
  }
  page_each_bonus_payments(each_bonus_payments) {
    return each_bonus_payments.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  sort_click(sort_key, id) {
    this.page = 1;
    sort_key = "sort_" + sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = "asc";
    } else if (this[sort_key] == "asc") {
      sort_mode = "desc";
    } else if (this[sort_key] == "desc") {
      sort_mode = "asc";
    }
    if (id) {
      this.set_sort_id(sort_key, id);
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    this.query = {
      bonus_sort_code: sort_key == "sort_code" ? sort_mode : undefined,
      bonus_sort_name: sort_key == "sort_name" ? sort_mode : undefined,
      bonus_sort_hiring_type: sort_key == "sort_hiring_type" ? sort_mode : undefined,
      bonus_sort_branch_id: sort_key == "sort_branch_id" ? sort_mode : undefined,

      bonus_sort_money: sort_key == "sort_money" ? sort_mode : undefined,
      bonus_sort_allowance: sort_key == "sort_allowance" ? sort_mode : undefined,
      bonus_sort_plus_sum: sort_key == "sort_plus_sum" ? sort_mode : undefined,

      bonus_sort_health_ins: sort_key == "sort_health_ins" ? sort_mode : undefined,
      bonus_sort_care_ins: sort_key == "sort_care_ins" ? sort_mode : undefined,
      bonus_sort_pension_ins: sort_key == "sort_pension_ins" ? sort_mode : undefined,
      bonus_sort_emp_ins: sort_key == "sort_emp_ins" ? sort_mode : undefined,
      bonus_sort_social_ins_adj: sort_key == "sort_social_ins_adj" ? sort_mode : undefined,
      bonus_sort_income_tax: sort_key == "sort_income_tax" ? sort_mode : undefined,
      bonus_sort_deduction: sort_key == "sort_deduction" ? sort_mode : undefined,
      bonus_sort_minus_sum: sort_key == "sort_minus_sum" ? sort_mode : undefined,

      bonus_sort_real_pay: sort_key == "sort_real_pay" ? sort_mode : undefined,
      bonus_sort_paid_cash: sort_key == "sort_paid_cash" ? sort_mode : undefined,
      bonus_sort_sum_tax: sort_key == "sort_sum_tax" ? sort_mode : undefined,
      bonus_sort_paid_on: sort_key == "sort_paid_on" ? sort_mode : undefined,

      bonus_sort_yearend_adj: sort_key == "sort_yearend_adj" ? sort_mode : undefined,
      bonus_sort_settlement: sort_key == "sort_settlement" ? sort_mode : undefined,
      bonus_sort_other_sum: sort_key == "sort_other_sum" ? sort_mode : undefined,

      bonus_sort_lock_type: sort_key == "sort_lock_type" ? sort_mode : undefined,
    };
  }
  set_sort_id(sort_key, id) {
    this.query = {
      bonus_sort_allowance_id: sort_key == "sort_allowance" ? id : undefined,
      bonus_sort_deduction_id: sort_key == "sort_deduction" ? id : undefined,
      bonus_sort_other_allowance_id: sort_key == "sort_other_allowance" ? id : undefined,
    };
  }

  get each_bonus_payments() {
    return this.monthly.each_bonus_payments;
  }

  get employee_hash() {
    return this.monthly.employee_hash;
  }
  get branch_hash() {
    return this.monthly.branch_hash;
  }
  get branch_hash_all() {
    return this.monthly.branch_hash_all;
  }

  get lock_types_hash() {
    return this.monthly.lock_types_hash;
  }

  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage("bonus_disp_num", val);
    manager.setDispNum(val);
  }

  get lockable_bonus() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_bonus_lock == 1);
  }
  get editable_bonus() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_bonus == 2);
  }
}
