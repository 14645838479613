
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonus from "@/mixins/mixinBonus";
  import global from "@/vuex/bonus/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";

  import CommonText from "@/components/common/Text.vue";
  import CommonAllowance from "@/components/common/Allowance.vue";
  import CommonDate from "@/components/common/Date.vue";

  @Component({
    components: {
      CommonText,
      CommonAllowance,
      CommonDate,
    },
  })
  export default class Row extends Mixins(mixinBonus) {
    @Prop() each_bonus_payment;

    change_each_bonus_allowance(id: number, val) {
      Vue.set(this.tmp_each_bonus_payment.each_bonus_allowances, id, val);
    }
    change_each_bonus_deduction(id: number, val) {
      Vue.set(this.tmp_each_bonus_payment.each_bonus_deductions, id, val);
    }
    change_each_bonus_other_allowance(id: number, val) {
      Vue.set(this.tmp_each_bonus_payment.each_bonus_other_allowances, id, val);
    }

    get tmp_each_bonus_payment() {
      return this.m.each_bonus_payment[this.each_bonus_payment.employee_id];
    }

    set tmp_each_bonus_payment(val) {
      this.m.setEachBonus({
        employee_id: this.each_bonus_payment.employee_id,
        each_bonus_payment_object: val,
      });
    }

    get is_edit() {
      return this.m.edit_each_bonus_payments_flag;
    }
  }
