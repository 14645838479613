
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinBonus from "@/mixins/mixinBonus";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonDate from "@/components/common/Date.vue";

  @Component({
    components: {
      UtilPopup,
      CommonDate,
    },
  })
  export default class Dialog extends Mixins(mixinBonus) {
    get tmp_bonus_info() {
      return this.m.bonus_info;
    }
    set tmp_bonus_info(value) {
      this.m.setBonusInfo({ bonus_info: value });
    }

    get is_display() {
      return this.m.is_open_edit_bonus_info_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenEditBonusInfoDialog(val);
    }

    update_bonus_info() {
      this.m.updateBonusInfo({ bonus_info_id: this.bonus_info_id });
    }
  }
