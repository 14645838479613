
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonus from "@/mixins/mixinBonus";
  import global from "@/vuex/bonus/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import BonusTableMain from "@/components/bonus/table/Main.vue";

  @Component({
    components: {
      CommonProgress,
      BonusTableMain,
    },
  })
  export default class Main extends Mixins(mixinBonus) {}
