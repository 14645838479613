
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinBonus from "@/mixins/mixinBonus";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonDate from "@/components/common/Date.vue";

  @Component({
    components: {
      UtilPopup,
      CommonDate,
    },
  })
  export default class Dialog extends Mixins(mixinBonus) {
    created() {
      const dateobj = new Date();
      this.tmp_bonus_info = {
        close_started_on: util.formatDate(dateobj, "yyyy-mm-dd"),
        close_ended_on: util.formatDate(dateobj, "yyyy-mm-dd"),
        paid_on: util.formatDate(dateobj, "yyyy-mm-dd"),
      };
    }

    get tmp_bonus_info() {
      return this.m.bonus_info;
    }
    set tmp_bonus_info(value) {
      this.m.setBonusInfo({ bonus_info: value });
    }

    get is_display() {
      return this.m.is_open_add_bonus_info_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenAddBonusInfoDialog(val);
    }
  }
